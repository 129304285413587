<template>
  <section class="w-full relative h-full">
    <Swiper
        :modules="[SwiperAutoplay, SwiperPagination]"
        :autoplay="{
              delay: 3000,
              disableOnInteraction: false,
            }"
        :slides-per-view="1"
        :space-between="0"
        :pagination="{
                el: '.slide-pagination1',
                clickable: true
            }"
        :speed="500"
        :grabCursor="true"
        :loop="true"
        class="overflow-hidden"
    >
      <SwiperSlide
          class="swiper-slide w-full !h-auto"
          v-for="item in dataBanner" :key="item?.id">
        <div class="absolute inset-0 -z-1">
          <img
              :src="getThumbnail(item?.img_bg?.id, 800, true)"
              :alt="item?.img_bg?.description || item?.img_bg?.title"
              class="w-full h-full object-cover"
          />
        </div>
        <div class="pt-15 xl:pt-20 container relative flex flex-col lg:flex-row h-full gap-5 lg:gap-10">
          <div class="lg:pb-22 w-full lg:w-1/2 flex flex-col">
            <p v-if="item?.caption_subtitle" class="text-white/90 text-sm leading-6 mb-4">
              {{ item?.caption_subtitle }}
            </p>
            <h1
                v-if="item?.caption_title"
                class="text-white font-[550] text-3xl lg:text-5xl xl:text-6xl lg:leading-15 xl:leading-20]"
            >
              {{ item?.caption_title }}
            </h1>
            <button-primary
                class="mt-8"
                :title="item?.btn_text"
                :url="item?.btn_link"
                option
                :click-tracking-handler="trackClick"
            ></button-primary>
          </div>

          <div
              class="w-24.5 lg:w-40 xl:w-45 aspect-45/34 absolute bottom-[35%] right-[65%] sm:right-[60%] sm:bottom-[40%] lg:top-[15%] lg:right-[30%] xl:top-[20%] xl:bottom-auto z-100"
          >
            <img
                src="/images/brand-iz4-3.png"
                alt="mascot"
                class="w-full h-full object-contain animationSway"
            />
          </div>

          <div class="lg:w-1/2 mt-auto aspect-1/1">
            <img
                :src="getThumbnail(item?.image?.id, 1200, true)"
                :alt="item?.image?.description || item?.image?.title"
                class="w-full h-full"
            />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

    <div class="container absolute inset-0">
      <div class="absolute top-5 left-5 lg:top-auto lg:bottom-[10%] z-20">
        <div class="slide-pagination1"></div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  DOWNLOAD_APP_BY_LINK_KEY, DOWNLOAD_EVENT_ID,
  useEventTracking
} from '../../../composables/ackee/event'

const {getHomeBanner} = useBanner()
const banner = await getHomeBanner(useRoute().params.langCode).then((response) => response)
const dataBanner = computed(() => {
  return banner?.home_banners?.banner_items
})

const trackClick = () => {
  useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_BY_LINK_KEY);
};
</script>

<style scoped>
.custom-title-banner {
  @apply text-64px leading-74px;
}

.custom-padding-banner {
  @apply px-0;
}
</style>
