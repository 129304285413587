<template>
  <section class="relative w-full">
    <div
        v-if="!isOpenVideo"
        @click="isLiveEditor ? '' : (isOpenVideo = !isOpenVideo)"
        class="w-full cursor-pointer group h-[calc(100vh-360px)] md:h-[calc(100vh-360px)] aspect-310/160 relative"
    >
      <v-image option_key="homepage.imageVideo" class="w-full h-full object-cover" :width="1400"></v-image>

      <div
          :class="isLiveEditor ? 'pointer-events-none' : 'pointer-events-auto'"
          class="bg-black/40 inset-0 absolute z-5"
      ></div>

      <div
          :class="isLiveEditor ? 'pointer-events-none' : 'pointer-events-auto'"
          class="absolute inset-0 z-20 p-10 lg:pt-31.5 container z-10"
      >
        <GlobalHeading
            :class="isLiveEditor ? 'pointer-events-auto' : 'pointer-events-none'"
            optKeyTitle="homepage.titleVideo"
            optKeySubtitle="homepage.subTitleVideo"
            title="Ứng dụng bảo hiểm tiện lợi và thông minh"
            subTitle="Giới thiệu về IZIon24"
            class="w-full max-w-100 md:flex hidden"
            sizeTitle="!text-30px !leading-44px !lg:text-36px !lg:leading-50px mt-5"
            light
        ></GlobalHeading>
      </div>
      <div class="absolute inset-0 z-20 flex justify-center items-center pointer-events-none">
        <IconsPlayPrimary
            class="w-10 h-10 md:w-15 md:h-15 transform group-hover:scale-115 duration-300"
        ></IconsPlayPrimary>
      </div>
    </div>
    <div v-else class="w-full h-[calc(100vh-79px)] md:h-[calc(100vh-96px)]">
      <iframe
          v-if="youtubeLinkRender && isOpenVideo"
          :src="youtubeLinkRender"
          frameborder="0"
          allowfullscreen
          allow="autoplay"
          class="w-full h-full aspect-310/160"
      ></iframe>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'section_video_home'
}
</script>

<script setup lang="ts">
const isOpenVideo = ref(false)
const isLiveEditor = inject('is_edit')
const config = useRuntimeConfig().public
const cookieAccessToken = config.directus?.cookieNameToken

const extractYouTubeVideoID = (url) => {
  const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  return match && match[1] ? match[1] : null
}
const video_homepage = inject('globalSettings')?.links[0]?.homepage

const youtubeLink = ref(video_homepage)
const videoID = extractYouTubeVideoID(youtubeLink.value)
const youtubeLinkRender = `https://www.youtube.com/embed/${videoID}?autoplay=1`
</script>

<style></style>
