<template>
  <section class="container mb-10 lg:mb-20" v-if="dataRender && dataRender.length > 0 && isSwiper">
    <GlobalHeading
        opt-key-title="homepage.titleProduct"
        title="Sản phẩm bảo hiểm IZIon24"
        width="w-full"
        class="mb-8"
        animation="zoom-in"
    ></GlobalHeading>
    <div class="relative h-full">
      <Swiper
          data-aos="zoom-in-up"
          data-aos-once="true"
          :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
          :autoplay="{
                    delay: 10000,
                    disableOnInteraction: false
                }"
          :pagination="{
                    dynamicBullets: true,
                    clickable: true
                }"
          :navigation="{
                    nextEl: '.next-swiper-product',
                    prevEl: '.prev-swiper-product'
                }"
          :breakpoints="{
                    1024: { slidesPerView: 3 },
                    624: { slidesPerView: 2 },
                    320: { slidesPerView: 1 }
                }"
          :grabCursor="true"
          :space-between="24"
          :loop="true"
          class="swiper-product-home my-10 h-full !px-2px"
      >
        <SwiperSlide class="relative !h-auto mb-10" v-for="product in dataRender" :key="product?.id">
          <GlobalBoxCardProduct :item="product"></GlobalBoxCardProduct>
        </SwiperSlide>
      </Swiper>
      <!-- <div class="mt-7.5 slide-pagination-product flex justify-center"></div> -->
      <GlobalNavigationSwiper
          v-if="dataRender.length > 3"
          nextEl="next-swiper-product"
          prevEl="prev-swiper-product"
          class="!xl:flex !hidden"
      >
      </GlobalNavigationSwiper>
    </div>
    <div class="w-full flex justify-center mt-11">
      <button-primary class="w-max !text-sm" :title="t('VIEW_ALL')" :url="t('ROUTER_PRODUCT')"></button-primary>
    </div>
  </section>
</template>
<script lang="ts">
export default {
  name: 'sectionProduct'
}
</script>
<script setup lang="ts">
const {getProductsHomepage} = useProducts()
const {t} = useI18n()
const isSwiper = ref(false)
const dataRender = await getProductsHomepage(useRoute().params.langCode).then((response) => response)

onMounted(() => {
  isSwiper.value = true
})
</script>

<style>
.swiper-product-home .swiper-pagination {
  @apply bottom-0 !w-28.5;
}

.swiper-product-home .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply !w-6.5;
}
</style>
