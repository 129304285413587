import {useParseData} from '../../../utils/parse.utils';
import {consola} from 'consola';
import {BannerTypeDto, BannerTypeEntity, BannerItemTypeEntity} from "./banner.type"
import {Logger} from '../../../utils/logger.utils'

export const useBannerUtils = () => {
  const {generateDefaultFieldDirectus, getTransImage, getTransItemMulti} = useParseData()
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);

  const generateBannerDto = (item: BannerTypeEntity, languageCode: string): BannerTypeDto | any => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        name: item.name,
        position: item.positions,
        time: item.time
      }
      if (item.banner_items && Array.isArray(item.banner_items) && item.banner_items.length > 0) {
        output['banner_items'] = item.banner_items.map((ite) =>
          typeof ite === 'object' ? generateBannerItemDto(ite, languageCode) : ite
        )
      }
      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateBannerDto : ', e);
      return {}
    }
  }

  const generateBannerItemDto = (item: BannerItemTypeEntity, languageCode: string): BannerTypeDto | any => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        date_from: item.date_from,
        date_to: item.date_to,
        ratio: item.ratio,
        fit_mode: item.fit_mode,
        image: getTransImage(item, languageCode, "image"),
        image_mobile: getTransImage(item, languageCode, "image_mobile"),
        img_bg: getTransImage(item, languageCode, "img_bg"),
        caption_subtitle: getTransItemMulti(item, languageCode, "caption_subtitle"),
        caption_title: getTransItemMulti(item, languageCode, "caption_title"),
        btn_text: getTransItemMulti(item, languageCode, "btn_text"),
        btn_link: getTransItemMulti(item, languageCode, "btn_link")
      }

      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateBannerItemDto: ', e);
      return {}
    }
  }

  return {
    generateBannerDto,
    generateBannerItemDto,
    logger
  }
}
