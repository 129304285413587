<template>
  <section class="container pt-10 lg:pt-20 relative overflow-hidden" v-if="res && res.length > 0">
    <GlobalHeading
        :formEditor="true"
        optKeyTitle="homepage.titleNews"
        title="Tin tức mới nhất"
        animation="zoom-in"
        sizeTitle="!text-25px !leading-36px !lg:text-34px !lg:leading-47px"
        class="mb-12"
    ></GlobalHeading>

    <div class="flex flex-col lg:flex-row gap-8">
      <nuxt-link
          data-aos="fade-right"
          data-aos-once="true"
          @click="removeAttribute(dataFirst)"
          :to="dataFirst?.url"
          class="flex flex-col lg:border-none border-b lg:pb-0 pb-8 border-bw-08 flex-1 group removeAnimationFeature"
      >
        <div class="aspect-178/120 w-full rounded-15px overflow-hidden mb-6">
          <img
              :src="getThumbnail(dataFirst?.thumbnail?.id, 715, true)"
              :alt="dataFirst?.thumbnail?.description || dataFirst?.thumbnail?.title"
              class="w-full h-full object-cover"
          />
        </div>
        <p class="flex gap-2 uppercase divide-x divide-bw-04 text-bw-04 text-13px leading-6 font-semibold">
          {{ dataFirst?.category?.title }}
        </p>

        <h3 class="leading-6 text-lg font-[550] text-black mb-2 group-hover:text-primary duration-400">
          {{ dataFirst?.title }}
        </h3>
        <p class="text-black/60 text-base leading-6 mt-6 lg:block hidden">
          {{ dataFirst?.intro }}
        </p>
      </nuxt-link>
      <div class="lg:w-108 lg:pl-8 !lg:border-l border-bw-08">
        <div class="mb-6 lg:block hidden">
          <div class="w-full aspect-108/40">
            <v-image
                option_key="homepage.imageNews"
                class="w-full h-full object-cover rounded-15px"
                :width="800"
            >
            </v-image>
          </div>
        </div>

        <div class="flex flex-col gap-8">
          <GlobalItemListNew
              data-aos="fade-left"
              data-aos-once="true"
              @click="removeAttribute(news)"
              v-for="news in dataRemaining"
              :key="news?.id"
              :dataItem="news"
              class="border-b border-bw-08 pb-8 removeAnimationFeature"
          ></GlobalItemListNew>
        </div>
        <nuxt-link
            :to="url"
            class="text-accent-01 flex text-sm leading-5 uppercase font-semibold hover:text-primary duration-200 mt-6"
        >
          <span>{{ t('SHOW_MORE') }}</span>
          <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {NEWS_EVENT_ID, useEventTracking} from "../../../composables/ackee/event";

const {getOnTop} = useIZNewsWidgets()
const {t} = useI18n()
const res = await getOnTop(useRoute().params.langCode, {
  query: {
    limit: 4
  }
})

const removeAttribute = (firstItem: any) => {
  useEventTracking(NEWS_EVENT_ID, `${firstItem?.category?.title} - ${firstItem?.title}`)

  document.querySelectorAll('.removeAnimationFeature').forEach((item) => {
    item.removeAttribute('data-aos')
  })
}

const dataFirst = computed(() => {
  return res[0]
})
const dataRemaining = computed(() => {
  return res.slice(1)
})
const currentRoute = useRoute().params.lang
const URLs = {
  vi: '/' + t('SHOW_MORE_URL_NEWS'),
  en: '/en/' + t('SHOW_MORE_URL_NEWS')
}
const url = ref(URLs[currentRoute])
</script>

<style></style>
