<template>
  <section class="mb-10 lg:mb-20 container overflow-hidden">
    <div
        class="relative h-full rounded-15px"
        style="
                background: radial-gradient(
                    162.55% 98.99% at 73.6% 64.69%,
                    #ffa17d 5.03%,
                    #ff8050 42.75%,
                    #ed5a22 100%
                );
            "
    >
      <div
          data-aos="zoom-in"
          data-aos-once="true"
          class="w-38 aspect-38/32 bottom-10 right-10 lg:top-[60%] lg:right-[38%] absolute z-15 lg:block hidden"
      >
        <img
            src="/images/global/masgotRight.png"
            :alt="item?.img_bg?.description || item?.img_bg?.title"
            class="w-full h-full object-contain animationSway"
        />
      </div>
      <div class="absolute z-1 inset-0 lg:block hidden">
        <div class="bottom-0 right-0 absolute z-1">
          <img class="w-full h-full object-contain" src="/images/circle/hinh1.svg" alt="circle"/>
        </div>
        <div class="top-0 left-0 absolute z-1">
          <img class="w-full h-full object-contain" src="/images/circle/hinh2.svg" alt="circle"/>
        </div>
      </div>
      <div class="bottom-0 right-0 absolute z-1 lg:hidden block">
        <img class="w-full h-full object-contain" src="/images/circle/hinh3.svg" alt="circle"/>
      </div>
      <div class="p-9.5 pb-0 gap-12 lg:gap-0 lg:pt-17 lg:pl-18 flex lg:flex-row flex-col w-full relative z-10">
        <div class="w-full max-w-156">
          <div data-aos="fade-down-right" data-aos-once="true" class="mb-5 lg:mb-20">
            <v-content
                class="font-[550] text-white text-32px leading-40px md:text-30px md:leading-44px max-w-112"
                default_value="Tải ứng dụng IZIon24 ngay <p class='text-16px leading-6 w-full lg:max-w-101 text-white/95 mt-4 font-normal'>IZION24 nuôi ước vọng mang đến sự bảo vệ đáng tin cậy cho người Việt thông quanhững ứng.</p>"
                option_key="homepage.contentBannerDownload"
            />
          </div>
          <div
              data-aos="fade-up"
              data-aos-once="true"
              class="flex md:flex-row flex-col gap-6 md:divide-x-1 md:divide-white/40"
          >
            <div class="flex flex-col gap-2.5">
              <div class="block">
                <v-text
                    tag="p"
                    default_value="Tải trực tiếp từ:"
                    option_key="subTitleBanerDownload1.global"
                    class="text-white/60 font-semibold text-sm leading-6 uppercase"
                />
              </div>
              <div class="space-y-2.5">
                <a
                    :href="links_global?.appstore"
                    @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_APP_STORE_KEY)"
                    target="_blank"
                    class="block w-37.5 aspect-38/12 overflow-hidden"
                >
                  <img
                      src="/images/global/appstore.svg"
                      alt="appstore"
                      class="w-full h-full object-contain"
                  />
                </a>
                <a
                    :href="links_global?.google_play"
                    @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY)"
                    target="_blank"
                    class="block w-37.5 aspect-38/12 overflow-hidden"
                >
                  <img
                      src="/images/global/googlePlay.svg"
                      loading="lazy"
                      alt="googleplay"
                      class="w-full h-full object-contain"
                  />
                </a>
              </div>
            </div>
            <div class="md:pl-6 space-y-2.5">
              <div class="block">
                <v-text
                    tag="p"
                    default_value="quét mã qr:"
                    option_key="subTitleBanerDownload2.global"
                    class="text-white/60 font-semibold text-sm leading-6 uppercase"
                />
              </div>
              <div class="block">
                <div class="w-27 aspect-1/1 overflow-hidden">
                  <img
                      :src="getThumbnail(links_global?.qr, 162, true)"
                      alt="qr"
                      class="w-full h-full object-contain rounded-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-left" data-aos-once="true" class="flex-1 flex justify-center">
          <div class="w-117 aspect-117/107 image-contain">
            <v-image
                option_key="homepage.imageBannerDownload"
                class="w-full h-full object-contain rounded-15px"
                :width="800"
            >
            </v-image>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  DOWNLOAD_EVENT_ID,
  DOWNLOAD_APP_ON_APP_STORE_KEY,
  DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY,
  useEventTracking
} from '../../../composables/ackee/event'

const links_global = inject('globalSettings')?.links[0]
</script>

<style></style>
