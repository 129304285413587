<template>
  <section class="!container mt-10 lg:mt-20 lg:pb-20 lg:border-b lg:border-bw-08">
    <GlobalHeading
        optKeyTitle="homepage.titlePrmontion"
        title="Chương trình khuyến mãi"
        animation="zoom-in"
        sizeTitle="!text-25px !leading-36px !lg:text-34px !lg:leading-47px"
        class="mb-12"
    ></GlobalHeading>
    <div class="relative">
      <Swiper
          data-aos="zoom-in-up"
          data-aos-once="true"
          :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
          :autoplay="{
                    delay: 10000,
                    disableOnInteraction: false
                }"
          :pagination="{
                    dynamicBullets: true,
                    clickable: true
                }"
          :navigation="{
                    nextEl: '.next-swiper-news',
                    prevEl: '.prev-swiper-news'
                }"
          :breakpoints="{
                    1024: { slidesPerView: 3 },
                    624: { slidesPerView: 2 },
                    320: { slidesPerView: 1 }
                }"
          :grabCursor="true"
          :space-between="24"
          :loop="true"
          class="swiper-promotion-home my-10"
      >
        <SwiperSlide class="mb-10" v-for="item in news" :key="item?.id">
          <global-box-card-event :dataItem="item"></global-box-card-event>
        </SwiperSlide>
      </Swiper>
      <!-- <div class="mt-7.5 slide-pagination-new flex justify-center"></div> -->
      <GlobalNavigationSwiper nextEl="next-swiper-news" prevEl="prev-swiper-news" class="!xl:flex !hidden">
      </GlobalNavigationSwiper>
    </div>
    <div class="w-full flex justify-center mt-10">
      <button-primary class="w-max !text-sm" :title="$t('SHOW_MORE')" :url="$t('ROUTER_PROMOTION')">
      </button-primary>
    </div>
  </section>
</template>
<script lang="ts">
export default {
  name: 'section_promotion'
}
</script>
<script setup lang="ts">
const globalData = ref(inject('globalSettings'))
const dataNews = computed(() => {
  return globalData.value?.project_settings[0]?.promotion_category?.news
})

const {generateNewsDto} = useIZNewsUtils(globalData.value)

const news = dataNews.value.map((ite: any) => {
  return generateNewsDto(ite, useRoute().params.langCode)
})
</script>

<style>
.swiper-promotion-home .swiper-pagination {
  @apply bottom-0 !w-28.5;
}

.swiper-promotion-home .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply !w-6.5;
}
</style>
