<template>
  <section class="relative w-full">
    <div
        class="absolute inset-0 -z-1"
        style="
                background: radial-gradient(
                    162.55% 98.99% at 73.6% 64.69%,
                    #ffa17d 5.03%,
                    #ff8050 42.75%,
                    #ed5a22 100%
                );
            "
    >
      <img
          src="/images/circle/hinh14.svg"
          alt="circle"
          class="w-200 lg:w-120 aspect-1/1 object-contain absolute bottom-0 left-0"
      />

      <img
          src="/images/circle/hinh21.svg"
          alt="circle"
          class="w-90 aspect-1/1 object-contain absolute bottom-0 lg:right-3% xl:right-35 lg:block hidden"
      />
    </div>

    <div class="container py-10 lg:py-20 relative z-3 flex gap-10 lg:flex-row flex-col">
      <div class="lg:w-80 xl:w-104 w-full">
        <GlobalHeading
            data-aos="fade-right"
            data-aos-once="true"
            optKeyTitle="homepage.titleTool"
            optKeySubtitle="homepage.subTitleTool"
            subTitle="Công cụ phân tích nhu cầu"
            title="Ứng dụng IZIon24 là giải pháp bảo hiểm thông minh chính thức ra mắt vào tháng 5/2022, với việc áp dụng những công nghệ hiện đại nhất."
            light
            sizeTitle="!text-2xl !leading-3xl !xl:text-30px !xl:leading-44px mt-3"
        ></GlobalHeading>
        <button-primary
            data-aos="fade-up"
            data-aos-once="true"
            class="mt-5 lg:mt-11"
            option
            :title="t('DISCOVER_NOW')"
            optionKey="homepage.buttonTool"
            optionValue="homepage.buttonToolValue"
        >
        </button-primary>
      </div>
      <div data-aos="zoom-in" data-aos-once="true" class="h-full aspect-228/122 flex-1 justify-end">
        <v-image
            option_key="homepage.imageTool"
            class="w-full h-full object-contain rounded-15px"
            :width="1600"
        >
        </v-image>
        <!-- <v-image
            :width="1500"
            option_key="homepage.imageTool"
            class="w-full h-full object-contain rounded-15px"
        /> -->
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  INSURANCE_ADVISE_EVENT_ID,
  INSURANCE_ADVISE_KEY,
  useEventTracking
} from '../../../composables/ackee/event'
const {t} = useI18n()
</script>

<style></style>
