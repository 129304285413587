<template>
  <main>
    <section-banner></section-banner>

    <div class="pb-10 lg:pb-20">
      <section-overview></section-overview>
      <section-product></section-product>
      <section-partner></section-partner>
      <section-video></section-video>
      <section-advantage></section-advantage>
      <section-baner-download></section-baner-download>
      <section-tool></section-tool>
      <section-promotion></section-promotion>
      <section-news></section-news>
    </div>
  </main>
</template>

<script setup lang="ts">
import SectionBanner from './components/SectionBanner.vue'
import SectionOverview from './components/SectionOverview.vue'
import SectionProduct from './components/SectionProduct.vue'
import SectionPartner from './components/SectionPartner.vue'
import SectionVideo from './components/SectionVideo.vue'
import SectionAdvantage from './components/SectionAdvantage.vue'
import SectionBanerDownload from './components/SectionBannerDownload.vue'
import SectionTool from './components/SectionTool.vue'
import SectionPromotion from './components/SectionPromotion.vue'
import SectionNews from './components/SectionNews.vue'

import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const settings = inject('globalSettings')
const {generateLangSwitch} = useGenerateUrl(settings)
const lang_switch = generateLangSwitch('home', useRoute().params.langCode)
useDirectusCollectionSeo('home', {})

const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = lang_switch
})
</script>
