import {Query} from '@directus/types';
import consola from "consola";
import {useBannerUtils} from './useBannerUtils';
import {BannerTypeEntity} from './banner.type';

export const useBanner = () => {
  const {getItems} = useDirectusItems()
  const {generateBannerDto, logger} = useBannerUtils()
  const getHomeBanner = async (languageCode: string) => {
    return getBannerGroupEntity({
      query: {
        filter: {
          _and: [
            {
              status: {
                _eq: 'published'
              }
            },
            {
              name: {
                _eq: "home"
              }
            }
          ]
        },
        fields: [
          'id',
          'name',
          'position',
          'time',
          'banner_items.id',
          'banner_items.status',
          'banner_items.sort',
          'banner_items.date_from',
          'banner_items.date_to',
          'banner_items.ratio',
          'banner_items.fit_mode',
          'banner_items.group',
          'banner_items.translations.*',
          'banner_items.translations.img_bg.title',
          'banner_items.translations.img_bg.id',
          'banner_items.translations.img_bg.description',
          'banner_items.translations.image_mobile.title',
          'banner_items.translations.image_mobile.id',
          'banner_items.translations.image_mobile.description',
          'banner_items.translations.image.title',
          'banner_items.translations.image.id',
          'banner_items.translations.image.description'],
      }
    })
      .then((data) =>
        ({
          home_banners: Array.isArray(data)
            ? generateBannerDto(data[0], languageCode)
            : [],

        }))
      .catch((e) => {
        logger.error('ERR getHomeBanner: ', e)
        return {}
      })
  }


  //===============================================
  const getBannerGroupEntity = (options?: {
    id?: string | number
    query?: Query
  }): Promise<Array<BannerTypeEntity> | any> => {
    let data: any = {
      collection: 'banner_group',
      params: {
        fields: ['*'],
        ...options?.query
      }
    }
    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }


    return getItems(data)
  }
  return {
    getHomeBanner,
    logger
  }
}
