<template>
  <section class="container customer">
    <global-heading
        optKeyTitle="homepage.titleCustomer"
        title="Đối tác cung cấp sản phẩm bảo hiểm"
        width="w-full"
        class="pb-6.5 border-b border-bw-08"
        animation="zoom-in"
    ></global-heading>

    <div class="h-35 lg:h-47.5 w-full flex items-center containerMarquee" v-if="partners">
      <Vue3Marquee pauseOnHover clone>
        <a
            :href="partner?.url"
            v-for="partner in partners"
            :key="partner?.id"
            class="!flex justify-center items-center"
            target="_blank"
        >
          <img
              :src="getThumbnail(partner?.thumbnail?.id, 150, true)"
              :alt="partner?.thumbnail?.description || partner?.thumbnail?.title"
              class="w-20 aspect-1/1 object-contain"
          />
        </a>
      </Vue3Marquee>
      <!-- <div class="wrapper-marquee">
          <div class="marquee">
              <div class="marquee_group move_left">

              </div>
              <div class="marquee_group move_left">
                  <template v-for="partner in partners" :key="partner?.id">
                      <a :href="partner?.url" class="!flex justify-center items-center">
                          <img :src="partner?.thumbnail" alt="partner" class="w-20 aspect-1/1 object-contain" />
                      </a>
                  </template>
              </div>
          </div>
      </div> -->
    </div>
  </section>
</template>
<script lang="ts">
export default {
  name: 'section_partner'
}
</script>
<script setup lang="ts">
const {getCarriers} = useCarrier()

const partners = await getCarriers(useRoute().params.langCode, {})
</script>

<style scoped></style>
